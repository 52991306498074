const Ticket = () => import('@/views/private/pdf/Ticket.vue')
const Report = () => import('@/views/private/pdf/Report.vue')
const Detail = () => import('@/views/private/pdf/Detail.vue')
const routes = [
  {
    path: "/pdf-detail",
    name: "pdf-detail",
    component: Detail,
    meta: {
      title: "Pdf"
    }
  },
  {
    path: "/pdf-report",
    name: "pdf-report",
    component: Report,
    meta: {
      title: "Pdf"
    }
  },
  {
    path: "/pdf-ticket",
    name: "pdf",
    component: Ticket,
    meta: {
      title: "Pdf"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})