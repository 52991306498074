<template>
  <div>
    <v-navigation-drawer color="#b62029" v-model="drawer" fixed app>
      <div id="nav" class="mt-2">
        <!-- <v-list v-for="(header, index) in menu" :key="index">
          <v-list-item nav class="text-body-1" :to="header.link" exact link :prepend-icon="header.icon">
            {{ header.label }}
          </v-list-item>
        </v-list> -->

        <v-list nav v-for="(header, index) in menu" :key="index">
          <template v-if="header.children">
            <v-list-group :value="header.label">
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :prepend-icon="header.icon"
                  :append-icon="null"
                  :title="header.label"
                ></v-list-item>
              </template>
              <v-list-item v-for="(child, idx) in header.children" :key="idx" :to="child.link" :title="child.label"></v-list-item>
            </v-list-group>
          </template>

          <template v-else>
            <v-list-item nav :to="header.link" exact :prepend-icon="header.icon">
              {{ header.label }}
            </v-list-item>
          </template>
        </v-list>
      </div>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn block prepend-icon="mdi-logout-variant" class="pa-xl-5 hidden-lg-and-up text-red-accent-4"
            @click="logout()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" color="#fff" fixed nav elevation="1" app class="box-shadow">
      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer" />
      <v-toolbar-title class="text-body-1 d-flex align-center" :class="mdAndDown ? '' : 'pl-5'">
        <span class="font-weight-bold black--text">
          P2H
        </span>
        <v-divider class="mx-3" vertical style="min-height: 25px !important;
          height: 25px;
          min-width: 3px;
          margin-top: auto;
          margin-bottom: auto;
          background: #dadada;">
        </v-divider>
        <span class="text-second">
          {{ route.meta.title }}
        </span>
      </v-toolbar-title>
      <v-spacer />
      <div class="pr-5 d-flex align-center">
        <div class="background-download">
          <v-btn prepend-icon="mdi-download" class="pa-xl-5 hidden-lg-and-up text-capitalize" @click="installApp">Install
            Apps</v-btn>
        </div>
        <!-- <v-icon class="text-right" color="grey">mdi-bell</v-icon> -->
      </div>
      <v-list class="hidden-md-and-down">
        <v-list-item prepend-avatar="https://cdn.vuetifyjs.com/images/john.png" :title="user.nama" :subtitle="user.email">
        </v-list-item>
      </v-list>

      <div class="pr-6 cursor-pointer hidden-sm-and-down" @click="logout">
        <v-card-title class="justify-center pb-1">
          <v-icon color="#ff6161">mdi-logout-variant</v-icon>
        </v-card-title>
        <v-card-text class="text-center pa-0 text-red" style="font-size: 10px">
          Keluar
        </v-card-text>
      </div>
    </v-app-bar>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useDisplay } from "vuetify";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { TokenService } from "@/service/Storage.Service.js"

const { mdAndDown } = useDisplay()
const drawer = ref(true);
const clipped = ref(false);
const menu = ref([]);
const menuItems = ref([
  {
    label: "Perusahaan",
    icon: "mdi-city",
    link: "/perusahaan",
  },
  {
    label: "Department",
    icon: "mdi-office-building",
    link: "/department",
  },
  {
    label: "Unit",
    icon: "mdi-truck",
    link: "/unit",
  },
  {
    label: "User",
    icon: "mdi-human-greeting",
    link: "/user",
  },
  {
    label: "P2H",
    icon: "mdi-clipboard-outline",
    link: "/p2h",
  },
  {
    label: "Report",
    icon: "mdi-book-open",
    link: "/report",
  },
])

const menuGroup = ref([
  {
    label: "Home",
    icon: "mdi-home",
    children: [
      {
        label: "Dashboard",
        link: "/dashboard",
      },
      {
        label: "Status Unit",
        to: "/status-unit",
      },
    ]
  },
]);

const detail = ref({})
const showInstallButton = ref(false)
const route = useRoute();
const store = useStore();
const deferredPrompt = ref(null);

const user = computed(() => {
  return store.state.user
})

onMounted(() => {
  window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  access()
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
});

const access = () => {
  if (user.value.role === 'operator') {
    menu.value = [
      {
        label: "Home",
        icon: "mdi-home",
        children: [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
        ]
      },
      {
        label: "Unit",
        icon: "mdi-truck",
        link: "/unit",
      },
      {
        label: "Scan Barcode Unit",
        icon: "mdi-qrcode-scan",
        link: "/scan-unit",
      },
      {
        label: "P2H",
        icon: "mdi-clipboard-outline",
        link: "/p2h",
      },
    ];
  } else if (user.value.role === 'mekanik') {
    menu.value = [
      {
        label: "Home",
        icon: "mdi-home",
        children: [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
        ]
      },
      {
        label: "P2H",
        icon: "mdi-clipboard-outline",
        link: "/p2h",
      },
    ];
  } else if (user.value.role === 'foreman') {
    menu.value = [
      {
        label: "Home",
        icon: "mdi-home",
        children: [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
        ]
      },
      {
        label: "Unit",
        icon: "mdi-truck",
        link: "/unit",
      },
      {
        label: "P2H",
        icon: "mdi-clipboard-outline",
        link: "/p2h",
      },
      {
        label: "Report",
        icon: "mdi-book-open",
        children: [
          {
            label: "Main Report",
            link: "/report",
          },
          {
            label: "Laporan Temuan P2H",
            link: "/report-temuan",
          },
        ]
      },
    ];
  } else if (user.value.role === 'superintendent') {
    menu.value = [
      {
        label: "Home",
        icon: "mdi-home",
        children: [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
        ]
      },
      {
        label: "Unit",
        icon: "mdi-truck",
        link: "/unit",
      },
      {
        label: "P2H",
        icon: "mdi-clipboard-outline",
        link: "/p2h",
      },
      {
        label: "Report",
        icon: "mdi-book-open",
        children: [
          {
            label: "Main Report",
            link: "/report",
          },
          {
            label: "Laporan Temuan P2H",
            link: "/report-temuan",
          },
        ]
      },
    ];
  } else if (user.value.role === 'subadmin') {
    menu.value = [
      {
        label: "Home",
        icon: "mdi-home",
        children: [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
          {
            label: "Status Unit",
            link: "/status-unit",
          },
        ]
      },
      {
        label: "Unit",
        icon: "mdi-truck",
        link: "/unit",
      },
      {
        label: "P2H",
        icon: "mdi-clipboard-outline",
        link: "/p2h",
      },
      {
        label: "Report",
        icon: "mdi-book-open",
        children: [
          {
            label: "Main Report",
            link: "/report",
          },
          {
            label: "Laporan Temuan P2H",
            link: "/report-temuan",
          },
        ]
      },
    ];
  }  else if (user.value.role === 'superadmin' || user.value.role === 'adminsystem') {
  menu.value = [
    {
      label: "Home",
      icon: "mdi-home",
      children: [
        {
          label: "Dashboard",
          link: "/dashboard",
        },
      ]
    },
    {
      label: "Department",
      icon: "mdi-office-building",
      link: "/department",
    },
    {
      label: "Unit",
      icon: "mdi-truck",
      link: "/unit",
    },
    {
      label: "User",
      icon: "mdi-human-greeting",
      link: "/user",
    },
    {
      label: "P2H",
      icon: "mdi-clipboard-outline",
      link: "/p2h",
    },
    {
        label: "Report",
        icon: "mdi-book-open",
        children: [
          {
            label: "Main Report",
            link: "/report",
          },
          {
            label: "Laporan Temuan P2H",
            link: "/report-temuan",
          },
        ]
      },
  ];
  if (user.value.role === 'adminsystem') {
    menu.value.splice(1, 0, {
      label: "Perusahaan",
      icon: "mdi-city",
      link: "/perusahaan",
    });
  }
}
} 

const handleBeforeInstallPrompt = (event) => {
  // Mencegah browser menampilkan prompt instalasi secara otomatis
  event.preventDefault();
  // Menyimpan deferredPrompt untuk digunakan nanti
  deferredPrompt.value = event;
  showInstallButton.value = true;
};

const installApp = () => {
  if (deferredPrompt.value) {
    // Menampilkan prompt instalasi
    deferredPrompt.value.prompt();
    // Menanggapi hasil prompt
    deferredPrompt.value.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      deferredPrompt = null;
      showInstallButton.value = false;
    });
  }
};


function logout() {
  TokenService.removeToken()
  window.location = '/'
}

</script>

<style>
.rounded-full {
  border-radius: 50px;
  background-color: blue;
}

.background-download {
  background: #b62029;
  border-radius: 10px;
  margin-right: 15px;
  color: white;
  font-size: 10px;
}
</style>
