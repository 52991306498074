const P2h = () => import(/* webpackPrefetch: true */ '@/views/private/p2h/Index.vue')
const Tambah = () => import('@/views/private/p2h/Tambah.vue')
const Edit = () => import('@/views/private/p2h/Edit.vue')
const Detail = () => import('@/views/private/p2h/Detail.vue')
const Ticket = () => import('@/views/private/p2h/Ticket.vue')
const ViewTicket = () => import('@/views/private/p2h/ViewTicket.vue')

const routes = [
  {
    path: "/p2h",
    name: "p2h",
    component: P2h,
    meta: {
      title: "P2H"
    }
  },
  {
    path: "/p2h/create/:uuid",
    name: "tambah p2h",
    component: Tambah,
    meta: {
      title: "Tambah P2h"
    }
  },
  {
    path: "/p2h/edit/:uuid",
    name: "edit p2h",
    component: Edit,
    meta: {
      title: "Edit P2h",
      roles: ["mekanik"]
    }
  },
  {
    path: "/p2h/detail/:uuid",
    name: "detail p2h",
    component: Detail,
    meta: {
      title: "Detail P2h"
    }
  },
  {
    path: "/p2h/view-ticket/:uuid",
    name: "view-ticket-p2h",
    component: ViewTicket,
    meta: {
      title: "View Ticket P2h"
    }
  },
  {
    path: "/p2h/create-ticket/:uuid",
    name: "create-ticket-p2h",
    component: Ticket,
    meta: {
      title: "Create Ticket P2h",
      roles: ["mekanik"]
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title,
    roles: route.meta.roles || []
  }
  return { ...route, meta }
})