const User = () => import(/* webpackPrefetch: true */ '@/views/private/user/Index.vue')

const routes = [
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      title: "User"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})