const Dashboard = () => import(/* webpackPrefetch: true */ '@/views/private/dashboard/Index.vue')
const StatusUnit = () => import(/* webpackPrefetch: true */ '@/views/private/dashboard/status-unit/Index.vue')
const routes = [
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Beranda"
    }
  },

  {
    path: "/status-unit",
    name: "status-unit",
    component: StatusUnit,
    meta: {
      title: "Beranda"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})