import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/Vuetify";
import validate from "./plugins/VeeValidate";
import axios from "./plugins/Axios";
import excel from './plugins/Excel'
import highcharts from './plugins/VueHighCharts'
import VueApexCharts from "vue3-apexcharts";
import VueEasyLightbox from 'vue-easy-lightbox'
import "@/assets/css/main.css";
import 'vuetify/dist/vuetify.css'  
import { TokenService } from "@/service/Storage.Service.js";
import { AgGridVue } from 'ag-grid-vue3';
import { Html5Qrcode } from 'html5-qrcode';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';




if (TokenService.getToken()) {
  store.commit("SET_TOKEN", TokenService.getToken());
  store.commit("SET_USER", JSON.parse(TokenService.getUser()));
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered:');
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

const app = createApp(App);

app.use(router);
app.use(store);
app.use(vuetify);
app.use(validate);
app.config.globalProperties.$html5Qrcode = Html5Qrcode;
app.use(VueApexCharts);
app.component('ag-grid-vue', AgGridVue);
app.use(highcharts)
app.use(VueEasyLightbox)
app.use(excel);
app.use(axios);

app.mount("#app");
