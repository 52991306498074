import { createStore } from 'vuex'

const store = createStore({
  state: {
    auth_user: null,
    token: '',
    authenticated: false,
    user: {},
    roles: '',
    data_pdf: {},
    filter: {
      start_date: null,
      end_date: null,
      page: 1,
      currentItemsPerPage: 10,
      page_label: 1,
      currentItemsPerPage_label: 10,
      status: [],
    }  
  },
  getters: {
  },
  mutations: {
    SET_TOKEN(state, accessToken) {
      state.token = accessToken
      state.authenticated = true
    },
    SET_USER(state, accessUser) {
      state.user = accessUser
    },
    SET_DATA_PDF(state, data_pdf) {
      state.data_pdf = data_pdf
    },
    SET_FILTER(state, filter) {
      state.filter = {...state.filter, ...filter}
    }
  },
  actions: {
    async getDataPdf(state, payload) {
      state.commit('SET_DATA_PDF', payload)
    },

    async setFilter(state, payload) {
      state.commit('SET_FILTER', payload)
    }
  },
  modules: {
  }
});

export default store;