import allRoutes from '@/router/routes/all.js'
import publicRoutes from '@/router/routes/public.js'
import dashboard from '@/router/routes/dashboard.js'
import unit from '@/router/routes/unit.js'
import user from '@/router/routes/user.js'
import p2h from '@/router/routes/p2h.js'
import report from '@/router/routes/report.js'
import perusahaan from '@/router/routes/perusahaan.js'
import department from '@/router/routes/department.js'
import pdf from '@/router/routes/pdf.js'

export default allRoutes.concat(
  publicRoutes,
  dashboard,
  unit,
  user,
  p2h,
  report,
  perusahaan,
  department,
  pdf
)