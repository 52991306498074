const Report = () => import(/* webpackPrefetch: true */ '@/views/private/report/Index.vue')
const ReportFindings = () => import(/* webpackPrefetch: true */ '@/views/private/report/IndexReportFindings.vue')
const routes = [
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: {
      title: "Report"
    }
  },
  {
    path: "/report-temuan",
    name: "report findings",
    component: ReportFindings,
    meta: {
      title: "Laporan temuan"
    }
  },
]

export default routes.map(route => {
  const meta = {
    all: false, 
    public: false,
    title: route.meta.title
  }
  return { ...route, meta }
})