import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';
import routes from '@/router/routes/index';
import checkUserRole from '@/middleware/roles.js'
import stores from '@/store/index.js'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/:catchAll(.*)",
      redirect: '/404'
    },
    {
      path: '/',
      redirect: '/dashboard'
    }
  ].concat(routes)
})

router.beforeEach((to, from, next) => {  
  const authenticated = store.state.authenticated
  const isAll = to.matched.some(record => record.meta.all)
  const onlyLoggedOut = to.matched.some(record => record.meta.onlyLoggedOut)
  const isPublic = to.matched.some(record => record.meta.public)
  const roles = store.state.user.role
  
  if (!isAll && !isPublic && !authenticated) {
    return next({
      path: "/login"
    })
  }
  if (authenticated && onlyLoggedOut) {
    return next("/")
  }

  if (to.meta && to.meta.roles && to.meta.roles.length > 0 && !to.meta.roles.includes(roles)) {
    return next({
      path: `/p2h/detail/${to.params.uuid}`,
    })
  }
  document.title = `${to.meta.title} | P2H`;
  next()
})

export default router;
